/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.alertClass {
  background-color: grey !important;
}

.clockinput {
  border: solid 1px #eee !important;
  border-color: #eee !important;
  border-width: 0px !important;
  --highlight-height: 0 !important;
  background: transparent !important;
  color: #e0dfdf;

}

.Cardinput {
  border: solid 1px #eee !important;
  border-color: #eee !important;
  border-width: 0px !important;
  --highlight-height: 0 !important;
  background: transparent !important;
  color: #252525;

}

.Cardinput:focus {
  outline: none !important;
  border: 1px solid #eee;
  box-shadow: 0 0 0px #eee;
  color: #252525;
}

.terminalAddCardformInput {
  border: solid 1px #eee !important;
  border-color: #eee !important;
  border-width: 0px !important;
  --highlight-height: 0 !important;
  background: transparent !important;
  color: #fff;

}

.terminalAddCardformInput:focus {
  outline: none !important;
  border: 1px solid #eee;
  box-shadow: 0 0 0px #eee;
  color: #fff;
}

.clockinput:focus {
  outline: none !important;
  border: 1px solid #eee;
  box-shadow: 0 0 0px #eee;
  color: #e0dfdf;
}

.styledInput {
  --border-color: #f2f3f3;
  --border-width: 1px;
  --box-shadow: 0px #eee;
  --highlight-height: 0;
  --background: #f3f2f2;

  margin-bottom: 5px;
}

.ContentDes {
  --padding: 45px !important;
}

.roundedInput8px {
  @extend .styledInput;
  --border-radius: 12px;
}

.roundedInput8pxFilter {
  --border-color: #f2f3f3;
  --border-width: 1px;
  --box-shadow: 0px #eee;
  --highlight-height: 0;
  --background: #f3f2f2;

  --border-radius: 15px;
}

.styledInputTimeLogging {
  --border-color: #eee;
  --border-width: 1px;
  --box-shadow: 0px #eee;
  --highlight-height: 0;
  --background: #faf8f8;

  margin-bottom: 5px;
}



.roundedInput4pxTimeLogging {
  @extend .styledInputTimeLogging;
  --border-radius: 4px;
  --size: 22px !important;
}

.roundedInput.ion-valid.ion-dirty {
  --border-color: var(--ion-color-success-shade);
}


/* Set the width to the full container and center the content */
ion-select {
  width: 100%;

  justify-content: center;
}

/* Set the flex in order to size the text width to its content */
ion-select::part(placeholder),
ion-select::part(text) {
  flex: 0 0 auto;
}

/* Set the placeholder color and opacity */
ion-select::part(placeholder) {
  color: #20a08a;
  opacity: 1;
}

/*
 * Set the font of the first letter of the placeholder
 * Shadow parts work with pseudo-elements, too!
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-elements
 */
ion-select::part(placeholder)::first-letter {
  font-size: 24px;
  font-weight: 500;
}

/* Set the text color */
ion-select::part(text) {
  color: #545ca7;
}

/* Set the icon color and opacity */
ion-select::part(icon) {
  color: #971e49;
  opacity: 1;
}

.IconSizeClockIn {
  display: inline-block;
  font-size: 40pt;
  margin-right: 30px !important;
  color: rgb(3, 122, 19) !important;

}

.IconSizeInfo {
  display: inline-block;
  font-size: 40pt;
  margin-right: 30px !important;
  color: rgb(22, 22, 22) !important;

}

.IconSizeClockOut {
  display: inline-block;
  font-size: 40pt;
  margin-right: 30px !important;
  color: rgb(42, 45, 243) !important;

}

.GelementClockIn {
  text-align: center !important;
}

.fixedContent {
  margin-top: 20px !important;
  height: 700px !important;
}

.ion-content-below {
  margin-top: 200px !important;
}


.clockInParagraphClokin {
  display: inline-block;
  background-color: rgb(4, 155, 29) !important;
  font-size: 14pt;
  border: solid 1px rgb(4, 155, 29) !important;
  border-radius: 7px !important;
  color: bisque !important;
  padding: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
  margin-bottom: 10px !important;

}

.infoparagraphe {
  display: inline-block;
  background-color: rgb(22, 22, 22) !important;
  font-size: 14pt;
  border: solid 1px rgb(22, 22, 22) !important;
  border-radius: 7px !important;
  color: bisque !important;
  padding: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
  margin-bottom: 10px !important;

}

.clockOutParagraphClokOut {
  display: inline-block;
  background-color: rgb(58, 61, 250) !important;
  font-size: 14pt;
  border: solid 1px rgb(58, 61, 250) !important;
  border-radius: 7px !important;
  color: #fff !important;
  padding: 2px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
  margin-bottom: 10px !important;

}

.clockInParagraphClokinClone {
  display: inline-block;

  font-size: 19pt;
  border: solid 1px rgb(4, 155, 29) !important;
  border-radius: 7px !important;
  color: bisque !important;
  padding: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
  margin-right: 10px !important;

}

.imgClass {
  width: 45% !important;

}

.CHover:hover {
  background-color: rgb(78, 95, 252) !important;
  padding: 7px !important;
  border-radius: 7px !important;
  box-sizing: var(--box-shadow);
  box-shadow: rgb(237, 237, 240) 2px 2px 2px 4px;
  color: #fdfdff !important;
  opacity: 1;
}

// $colors: (
//    blue:    #387ef5,
//    secondary:  #32db64,
//    danger:     #f53d3d,
//    light:      #f4f4f4,  // the light color we're using
//    CustumDark:          rgb(107, 106, 106)   // the dark color we're using
// );



.new-background-color {
  --background: #fff;
  --color: #222;
  --padding-start: 12px;
}

.new-background-button-color {
  --background-color: transparent !important;
  // color: #000;

}

.new-icon-color {
  --color: #222 !important;
}

.new_class_content {
  overflow-y: auto;
}

.SubsTime {
  font-size: 14pt !important;
  font-weight: 600 !important;
  color: #747171 !important;
}

.new-content-background-color {
  --background: #e0dfdf;
  opacity: 1 !important;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.new-form-content-background-color {
  --background: #ffffff;
  opacity: 1 !important;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.attendance-categ-background-color {
  --background: #04c2f1 !important;
  --font-size: 2pt !important;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.attendance-online-background-color {
  --background: #fccf09e3 !important;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.attendance-toolbar-background-color {
  --background: #fff !important;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // --padding-end:50px !important;
}

@media only screen and (max-width: 600px) {
  .attendance-toolbar-background-color {
    --background: #fff !important;
    --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //  --padding-end:200% !important;
  }

}


.attendance-bootom-toolbar-background-color {
  --background: #fff !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.attendance-btnselect-background-color {
  --background: #06a8e7 !important;
  --color: #fff !important;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


// ion-input {
//   --highlight-height: 0px;
// }

// ion-item {
//   --highlight-color-focused: none;
//   }

ion-item {
  --highlight-color-valid: rgb(158, 157, 157);
  --highlight-color-invalid: rgb(158, 157, 157);
  --highlight-color-focused: #222;
}


.msg {
  font-size: 14px !important;
}

.terminalTime {
  margin-right: 49% !important;
}


.downloaded {
  --color: #fff !important;
  --background: #92949C !important;
}

.stopped {
  --background: #476bfa !important;
  --color: #ffffff !important;
}

.closed {
  --background: #d32d2d !important;
  --color: #ffffff !important;
}

.started {
  --background: #2DD36F !important;
  --color: #ffffff !important;
}

.active {
  --background: #04c2f1 !important;
  color: #fff !important;
}

// wrap ion-select text to next line
.sc-ion-label-md-h {
  white-space: normal !important;
}


.formtext {
  text-align: right;
  font-size: 17px;
  font-style: normal;
  margin-right: 15px;
  color: #878888;
}

.formtextEnable {
  text-align: right;
  font-size: 17px;
  font-style: normal;
  top: 0px;
  margin-top: 7px;
  margin-right: 15px;
  color: #878888;
}

.formtextEnablemobile {
  text-align: right;
  font-size: 17px;
  font-style: normal;
  top: 0px;
  margin-top: 7px;
  margin-right: 17px;
  color: #878888;
}


.formtextMobile {
  text-align: left;
  font-size: 17px;
  font-style: normal;
  top: 0px;
  margin-top: 7px;
  margin-right: 15px;
  color: #878888;
}

@media only screen and (max-width: 600px) {
  .formtextEnable {
    display: none;
    text-align: right;
    font-size: 17px;
    font-style: normal;
    top: 0px;
    margin-top: 7px;
    margin-right: 15px;
    color: #878888;
  }



}

@media only screen and (min-width: 600px) {
  .formtextEnablemobile {
    display: none;
    text-align: right;
    font-size: 17px;
    font-style: normal;
    top: 0px;
    margin-top: 7px;
    margin-right: 15px;
    color: #878888;
  }

}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .wrap-text {
    word-wrap: break-word !important;
    // word-break: break-all !important;
    width: 125px;
    // height: 100px;
    // --height: 100px !important;
  }

  .bwrap {
    height: 45px;
  }
}

button.alert-button.alert-button-cancel-calendar {
  color: rgb(124, 124, 124);
}

.virtual-image-sub {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
  margin-right: 5px;
}

.real-image-sub {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-right: 5px;
}

.virtual-image-main {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  margin-right: 10px;
}

.real-image-main {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.trash-delete:hover {
  cursor: pointer;
}

.class-div {
  background-color: #3880FF;
  color: #FFFFFF;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px !important;
  font-size: 13px;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
}

.category-div {
  @extend .class-div;

  padding: 1px;
  padding-left: 5px;
  padding-right: 5px;
}

ion-popover .popover-viewport {
  display: contents;
}

ion-modal#failed-confirmation-modal {
  --width: fit-content;
  --min-width: 70%;
  --max-width: 90%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.5);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);

  --border-color: red;
  --border-width: 5px;
}


/* The Modal (background) */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1000000;
  /* Sit on top */
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  width: fit-content;
  min-width: 75%;
  max-width: 90%;
  height: fit-content;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

button.alert-button.secondary-logout-button {
  color: gray;
}

button.alert-button.alert-button-cancel {
  color: gray;
}

button.alert-button.alert-button-delete {
  color: red;
}

.create-edit-item {
  max-width: 350px;

  --highlight-height: 2px;
  --highlight-color-focused: black;
  --highlight-color-valid: black;
  --highlight-color-invalid: black;

  --inner-padding-bottom: 0px;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-top: 0px;
  --min-height: 10px;
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
}




.reset-password-alert .roundedInput8px {
  @extend .roundedInput8px;
}

input.alert-input.roundedInput8px {
  @extend .roundedInput8px;
}

.reset-app-alert .alert-button-group {
  padding: 5px;
}

button.alert-button.alert-button-confirm {
  background-color: #06a8e7;
  color: white
}

button.alert-button.alert-button-cancel {
  background-color: rgb(172, 171, 171);
  color: white;
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

ion-modal.filter-body,
ion-modal.search-session-type-category-modal {
  --height: 190px;
  --width: 444px;
  --border-radius: 6px;
}

ion-modal.filter-body .ion-page {
  justify-content: space-around;
}

ion-modal.session-type-category-modal {
  --width: 350px;
  --height: 400px;
  --max-height: 550px;
  --border-radius: 6px;
}

ion-modal.session-types-location-modal,
ion-modal.create-session-location-modal {
  --width: 330px;
  --height: 400px;
  --border-radius: 6px;
}

ion-modal.modal-default.create-session-location-modal {
  --height: 500px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.from-middle-modal {
  --width: 500px;
  --height: 355px;
}

ion-modal.customAlertOptions {
  --width: 400px;
  --height: 600px;
}

ion-alert.customAlertOptions {
  --max-width: 340px !important;
  --width: 340px !important;
  --max-height: 390px !important;
}

ion-modal.account-groups-modal,
ion-modal.account-users-modal {
  --width: 350px !important;
  --max-height: 500px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.session-form-create {
  --height: 495px;
  --width: 480px;
}

ion-modal.session-form-session-type-modal,
ion-modal.session-all-modal {
  --width: 330px;
  --height: 500px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}